<template>
  <div class="form-archive-members" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <div class="subtitle mb-10">선택한 회원으로 신청서를 추가합니다.</div>
      <div class="header row">
        <div class="left col-lg-6">
          <div class="row mt-10">
            <div class="col-10">
              <select class="form-control border-focus-purple font-xs" v-if="modalParams.type === 'add'" :disabled="!state.loaded" v-model="state.selectedFormId">
                <option value="">신청서를 선택하세요.</option>
                <option :value="s.id" v-for="(s, idx) in state.forms" :key="idx">{{ s.title }}</option>
              </select>
            </div>
          </div>
        </div>
        <form class="search col-lg-6" @submit.prevent="setCondition(state.search.key, state.search.value)">
          <div class="row mt-10">
            <div class="pl-0 pr-0">
              <select class="form-control border-focus-purple font-xs w100" :disabled="!state.loaded" v-model="state.search.key">
                <option :value="s.name" v-for="(s, idx) in conditions" :key="idx">{{ s.title }}</option>
              </select>
            </div>
            <div class="col-6 pl-0 pr-0">
              <input type="text" class="form-control border-focus-purple font-xs" :disabled="!state.loaded" v-model="state.search.value"/>
            </div>
            <div class="col-2 pl-0 pr-0">
              <button class="px-0 w-100 btn btn-purple font-xs" :disabled="!state.loaded" type="submit">검색</button>
            </div>
          </div>
        </form>
      </div>
      <div class="body">
        <div class="table-responsive" :class="{skeleton: !state.loaded}" v-if="state.list.length">
          <table class="table table-hover mb-10">
            <thead>
            <tr>
              <th>
                <span>아이디</span>
              </th>
              <th>
                <span>이름 / 기업명</span>
              </th>
              <th>
                <span>휴대전화</span>
              </th>
              <th>
                <span>가입일</span>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(m, idx) in state.list" :key="idx">
              <td>
                <a class="pointer-u color-purple underline" @click="submit(m.memberSeq)">{{ m.memberId }}</a>
              </td>
              <td>
                <span>{{ m.memberName }} / {{ m.corporateName || "(N/A)" }}</span>
              </td>
              <td>
                <span>{{ $lib.getPhoneNumberFormat(m.mobile, true) }}</span>
              </td>
              <td>
                <span>{{ m.createDate ? m.createDate.substring(0, 10) : "" }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <NoData v-else/>
      </div>
      <PaginationJpa className="btn-primary" :change="loadMembers" :total="state.total" :pageable="state.pageable" v-if="state.pageable.size"/>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import PaginationJpa from "@/components/PaginationJpa.vue";
import NoData from "@/components/NoData.vue";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "modalArchiveApplicationMembers";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, PaginationJpa},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      state.loaded = false;
      store.commit("tuneModal", {component, size: "md"});

      loadForms();
      loadMembers(undefined, true);
    });

    const modalParams = store.getters.modalParams(component);

    const state = reactive({
      loaded: false,
      args: {
        page: 0,
        size: 10,
      },
      search: {
        key: "corporateName",
        value: "",
      },
      conditions: {},
      total: 0,
      pageable: {},
      list: [],
      forms: [],
      selectedForm: null,
      selectedFormId: "",
    });

    const conditions = [
      {
        name: "corporateName",
        title: "기업명",
      }, {
        name: "memberId",
        title: "아이디",
      }, {
        name: "memberName",
        title: "이름",
      }, {
        name: "mobile",
        title: "휴대전화",
      },
    ];

    const loadForms = () => {
      const formArgs = {
        page: 0,
        size: 10000,
      };

      http.get("/api/admin/archives", formArgs).then(({data}) => {
        state.forms = data.body.content;
      });
    };

    const loadMembers = (num) => {
      state.list = [];
      state.pageable.size = 0;
      state.pageable.page = 0;
      state.args = {
        page: 0,
        size: 10,
      };
      state.args.page = num;

      for (let i in state.conditions) {
        state.args[i] = state.conditions[i];
      }

      for (let i = 0; i < 10; i += 1) {
        state.list.push({
          memberId: "Wait a moment",
          memberName: "Wait a moment",
          mobile: "01012345678",
          createDate: "Wait a moment",
        });
      }

      state.loaded = false;
      http.get("/api/admin/members", state.args).then((res) => {
        if (JSON.stringify(state.args) === JSON.stringify(res.config.params)) {
          state.loaded = true;
          state.total = res.data.body.total;
          state.pageable = res.data.body.pageable;
          state.list = res.data.body.content;
        }
      });
    };

    const setCondition = (key, value) => {
      if (!key || !value) {
        return;
      }

      if (state.conditions[key] === value) {
        return removeCondition(key);
      }

      state.conditions[key] = value;
      loadMembers(0);
    };

    const removeCondition = (key) => {
      delete state.conditions[key];
      loadMembers(0);
    };

    watch(() => state.selectedFormId, (newVal) => {
      if (newVal === "") {
        state.selectedForm = null;
      } else {
        const selectedForm = state.forms.find(form => form.id === newVal);
        state.selectedForm = selectedForm || null;
      }
    });

    const submit = (memberSeq) => {
      const args = {
        memberSeq: memberSeq,
      };

      if (modalParams.type === "add") {
        if (!state.selectedForm) {
          alert("신청서를 선택해주세요.");
          return;
        }

        const selectedFormId = state.selectedForm.id;
        const selectedFormName = state.selectedForm.name;

        store.commit("confirm", {
          message: "선택한 회원으로 신청서를 추가하시겠습니까?",
          allow() {
            http.post(`/api/archives/${selectedFormId}/applications`, args).then(({data}) => {
              window.open(`/archives/${selectedFormName}/applications/${data.body}`, "_blank", "noopener,noreferrer");
              store.commit("closeModal", {
                name: component.name,
                onClose(modal) {
                  store.dispatch("callback", {modal});
                }
              });
            });

            loadMembers();
          },
        });
      } else if (modalParams.type === "copy") {
        // 오마이폼 -> 아카이브 신청서 이동
        store.commit("confirm", {
          message: "오미아폼 신청서를 아카이브 신청서로 복사하시겠습니까?",
          allowTxt: "이동",
          allow() {
            http.post(`/api/admin/forms/${modalParams.formId}/applications/${modalParams.applicationId}/copy-to-archive`, args).then(() => {
              store.commit("closeModal", {
                name: component.name,
                onClose(modal) {
                  store.dispatch("callback", {modal});
                  store.commit("setSwingMessage", "아카이브 신청서로 복사했습니다.");
                }
              });
            });
          },
        });
      }
    };

    return {component, state, modalParams, conditions, loadMembers, loadForms, setCondition, removeCondition, submit,};
  }
});
</script>

<style lang="scss" scoped>
.form-archive-members {
  > .wrapper {
    //min-height: $px600;
    background: #fff;
    padding: $px25;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      position: relative;
      margin-bottom: $px20;

      .title {
        font-size: $px18;
      }

      .search {
        width: auto;

        select, .date {
          width: 33.3333%;
        }

        .form-control {
          border-radius: 0;
          height: $px43;
          position: relative;
          left: $px2;

          &:focus {
            z-index: 1;
          }
        }

        &::v-deep {
          .date {
            .form-control {
              border-radius: 0;
              height: $px43;

              &:focus {
                z-index: 1;
              }
            }

            &:not(:last-child) .form-control {
              position: relative;
              left: $px1;
            }
          }
        }

        .row {
          > div {
            select {
              position: relative;
              border-radius: $px4 0 0 $px4;
              left: $px1;

              &:focus {
                position: relative;
                z-index: 1;
              }
            }

            input[type=text] {
              border-radius: 0;
              border-right: 0;
              padding-right: $px34;
            }

            .btn {
              border-radius: 0 $px4 $px4 0;
              height: 100%;
            }

            .dropdown {
              width: 100%;

              .btn {
                padding: $px10 $px12;
                width: 100%;
                border: $px1 solid $colorBorder;
              }

              .dropdown-menu {
                padding: $px15;
                min-width: $px230;
                transform: translate3d($px-175, $px43, 0) !important;

                .section {
                  .title {
                    font-size: $px14;
                    margin-bottom: $px4;
                  }

                  .dropdown-item {
                    border: $px1 solid $colorBorder;
                    border-radius: $px100;
                    cursor: pointer;
                    display: inline-block;
                    font-size: $px11;
                    padding: $px4 $px12;
                    margin: $px4 $px4 $px4 0;
                    width: auto;

                    &.active {
                      border-color: $colorPurple;
                      color: $colorPurple;
                    }
                  }

                  &:not(:last-child) {
                    margin-bottom: $px10;
                  }
                }
              }
            }
          }
        }
      }
    }

    .body {
      flex-grow: 1;
    }

    .member-list {
    }
  }

  &.skeleton {
    .header {
      .total span {
        @include skeleton;
      }
    }

    .body {
      table tr {
        th, td {
          a, span {
            @include skeleton;
          }
        }
      }

      &::v-deep {
        .pagination {
          .btn {
            @include skeleton;
          }
        }
      }
    }
  }
}
</style>